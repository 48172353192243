import * as Sentry from '@sentry/react'
import { PostHogProvider } from 'posthog-js/react'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './scaffold.css'
import { AuthProvider, useAuth } from './auth'

import './index.css'
// import 'intro.js/introjs.css'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://4d4ccc012635e517019ecffe578a424e@o4505134687977472.ingest.sentry.io/4506230476177408',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/landingsite\.ai\//],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const options = {
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
}

const App = () => (
  <PostHogProvider apiKey={process.env.PUBLIC_POSTHOG_KEY} options={options}>
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider useAuth={useAuth}>
            <Routes />
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  </PostHogProvider>
)

export default App
