type TrainingWebsitesNavBarLayoutProps = {
  children?: React.ReactNode
}

const TrainingWebsitesNavBarLayout = ({
  children,
}: TrainingWebsitesNavBarLayoutProps) => {
  return (
    <>
      <div className="h-screen">
        <div className="min-w-full border border-b-gray-200 bg-white">
          <header id="main-header" className="py-3">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-8">
                  <a
                    href="/"
                    className="inline-block text-xl font-bold tracking-tight text-gray-800 sm:text-2xl"
                  >
                    <img
                      src="/logo_full_black.png"
                      className="mr-2 inline h-5"
                      alt="Landingsite"
                    />
                    Landingsite.ai
                  </a>
                  <a href="/fine-tuning/playground" className="inline-block">
                    Playground
                  </a>
                  <a href="/training-websites" className="inline-block">
                    Training Websites
                  </a>
                  <a href="/template-editor" className="inline-block">
                    Template Editor
                  </a>
                </div>
              </div>
            </div>
          </header>
        </div>
        <main className="h-full">{children}</main>
      </div>
    </>
  )
}

export default TrainingWebsitesNavBarLayout
