import { Fragment, useState } from 'react'

import { Popover, Transition } from '@headlessui/react'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'

type AppNavBarLayoutProps = {
  children?: React.ReactNode
}

const GET_STRIPE_CUSTOMER_PORTAL_SESSION_URL = gql`
  mutation GetStripeCustomerPortalSessionUrlMutation {
    getStripeCustomerPortalSessionUrl
  }
`

const AppNavBarLayout = ({ children }: AppNavBarLayoutProps) => {
  const { loading, isAuthenticated, logIn, signUp, logOut } = useAuth()

  const [loadingBillingPortal, setLoadingBillingPortal] = useState(false)

  const [getStripeCustomerPortalSessionUrl] = useMutation(
    GET_STRIPE_CUSTOMER_PORTAL_SESSION_URL
  )

  const onClickLogin = (event) => {
    event.preventDefault()
    logIn({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }

  const onClickSignup = (event) => {
    event.preventDefault()
    signUp({
      appState: {
        targetUrl: `/login-success?redirectTo=${encodeURIComponent(
          window.location.pathname + window.location.search
        )}`,
      },
    })
  }

  const navigateToBillingPortal = async () => {
    setLoadingBillingPortal(true)
    const res = await getStripeCustomerPortalSessionUrl()

    console.log(res)

    window.location.href = res.data.getStripeCustomerPortalSessionUrl
  }

  return (
    <>
      <div className="min-h-full">
        <div className="h-full min-w-full bg-white">
          <header id="main-header" className="py-3">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between">
                <a
                  href="/"
                  className="flex items-center text-xl font-bold tracking-tight text-gray-800 sm:text-2xl"
                >
                  <img
                    src="/logo_full_black.png"
                    className="mr-2 inline h-5"
                    alt="Landingsite"
                  />
                  Landingsite.ai
                </a>
                {!loading && !isAuthenticated && (
                  <ul className="flex items-center gap-x-8 text-sm text-black">
                    <li>
                      <a
                        className="hover:underline"
                        href="/login"
                        onClick={onClickLogin}
                      >
                        Log In
                      </a>
                    </li>
                    <li>
                      <a
                        className="rounded bg-lime-400 px-4 py-2 text-sm hover:bg-lime-500"
                        href="/signup"
                        onClick={onClickSignup}
                      >
                        Sign Up
                      </a>
                    </li>
                  </ul>
                )}
                {!loading && isAuthenticated && (
                  <Popover className="relative flex">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`
                          ${open ? '' : 'text-opacity-90'}
                          group inline-flex h-[32px] w-[50px] flex-grow items-center justify-center rounded-lg bg-lime-400 p-2 text-base font-medium text-gray-800 hover:bg-gray-700 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                        >
                          <i className="fa-solid fa-user mr-1"></i>
                          <i className="fa-regular fa-chevron-down"></i>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute -left-3/4 z-[80] mt-10 w-[180px] -translate-x-1/2 transform px-4 sm:w-[140px] sm:px-0">
                            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                              <a
                                href="/"
                                className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                              >
                                <span>Dashboard</span>
                                <i className="fa-solid fa-user"></i>
                              </a>
                              <button
                                className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                                onClick={navigateToBillingPortal}
                              >
                                <span>Billing</span>
                                {!loadingBillingPortal && (
                                  <i className="fa-regular fa-credit-card"></i>
                                )}
                                {loadingBillingPortal && (
                                  <i className="fa-regular fa-spinner-third animate-spin"></i>
                                )}
                              </button>
                              <button
                                className="flex w-full items-center justify-between bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
                                onClick={() => {
                                  logOut({
                                    logoutParams: {
                                      returnTo: 'https://www.landingsite.ai/',
                                    },
                                  })
                                }}
                              >
                                <span>Log Out</span>
                                <i className="fa-solid fa-sign-out"></i>
                              </button>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                )}
              </div>
            </div>
          </header>
        </div>
        <main className="h-full">{children}</main>
      </div>
    </>
  )
}

export default AppNavBarLayout
