import { Toaster } from '@redwoodjs/web/toast'

type ChatLayoutProps = {
  children?: React.ReactNode
}

const ChatLayout = ({ children }: ChatLayoutProps) => {
  return (
    <>
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />

      {children}
    </>
  )
}

export default ChatLayout
